import { ConsoleLogger, ILogger } from '@gp/utility';
import { IConnectionParametersFactory, IHubOptions } from "./common";

/**
 * Default hub options.
 */
export abstract class BaseHubOptions implements IHubOptions {
    autoResubscribe?: boolean = true;
    enableLogging: boolean = false;

    /**
     * Name of the hub
     */
    abstract name: string;

    locale: string = 'en';

    commonEventNames: string[] = [
        'SubscriptionUpdate',
        'CountUpdate',
        'MenuUpdate'
    ];

    enableCompressedMessageLogging: boolean = false;

    logger: ILogger = new ConsoleLogger();
}