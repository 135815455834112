export class PropertyParam<T> {
    /**
     * Equal to single item or is included in array
     */
    eq?: T | T[];
    /**
     * Not equal to single item or is not part of array
     */
    "!eq"?: T | T[];
    /**
     * Less than
     */
    lt?: T;
    /**
     * Greater than
     */
    gt?: T;
}