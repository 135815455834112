(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mobx"), require("@gp/utility"), require("rxjs"));
	else if(typeof define === 'function' && define.amd)
		define(["mobx", "@gp/utility", "rxjs"], factory);
	else if(typeof exports === 'object')
		exports["gp"] = factory(require("mobx"), require("@gp/utility"), require("rxjs"));
	else
		root["gp"] = factory(root["mobx"], root["@gp/utility"], root["rxjs"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__965__, __WEBPACK_EXTERNAL_MODULE__50__, __WEBPACK_EXTERNAL_MODULE__435__) {
return 