import 'signalr';

import { ArgumentNullError } from "@gp/utility";
import { IConnection, IConnectionFactory, IConnectionOptions, IConnectionParametersFactory } from "./common";
import { HubConnectionGeneric } from './common/IConnectionFactory';
import { HubConnection } from './HubConnection';


function sanitizeServerUrl(url: string): string {
    if (url[url.length - 1] !== '/') {
        url += '/'
    }

    return url;
}

export interface IHubConnectionOptions {
    serverUrl: string;
    enableLogging?: boolean;
    HubConnectionType?: HubConnectionGeneric;
}

export class HubConnectionFactory implements IConnectionFactory {
    protected connectionParametersFactory: IConnectionParametersFactory;

    protected url: string;
    protected logging: boolean;
    protected HubConnectionType: HubConnectionGeneric = HubConnection;

    constructor(connectionParametersFactory: IConnectionParametersFactory, hubConnectionOptions: IHubConnectionOptions) {
        if (connectionParametersFactory == null) {
            throw new ArgumentNullError('connectionParametersFactory');
        }

        if (hubConnectionOptions == null) {
            throw new ArgumentNullError('hubConnectionOptions');
        }

        if (hubConnectionOptions.HubConnectionType != null) {
            this.HubConnectionType = hubConnectionOptions.HubConnectionType;
        }

        this.url = sanitizeServerUrl(hubConnectionOptions.serverUrl) + 'sync';
        this.logging = hubConnectionOptions.enableLogging || false;

        this.connectionParametersFactory = connectionParametersFactory;
    }

    /**
     * Gets SignalR connection
     * @returns SignalR connection
     */
    protected getConnection = () => {
        return $.hubConnection(this.url, {
            logging: this.logging,
            qs: this.connectionParametersFactory.get()
        });
    }

    /**
     * Creates SignalR Hub connection
     * 
     * @param options pass custom connection options to this connection
     */
    createConnection(options?: IConnectionOptions): IConnection {
        const conn = this.getConnection();

        return new this.HubConnectionType(conn, options);
    }

}