import { decorate, observable, action, runInAction } from 'mobx';
import 'signalr';

import { ArgumentNullError, isStringNullOrEmpty } from "@gp/utility";
import { HubState, IConnection, IConnectionOptions } from "./common"

class HubConnection implements IConnection {
    private reconnectTimeoutId: number;

    state: HubState = HubState.NOT_INITIALIZED;
    error: SignalR.ConnectionError = null;

    hubConnection: SignalR.Hub.Connection;
    hubProxy: SignalR.Hub.Proxy;

    /**
     * True if disconnect was triggered by user
     */
    isManualDisconnect: boolean = false;

    options: IConnectionOptions = {
        autoReconnect: true,
        reconnectInterval: 5000
    };

    constructor(signalrConnection: SignalR.Hub.Connection, options?: IConnectionOptions) {
        if (signalrConnection == null) {
            throw new ArgumentNullError('signalrConnection');
        }

        if (options != null) {
            this.options = options;
        }

        this.hubConnection = signalrConnection;

        this.hubConnection
            .starting(() => runInAction(() => {
                this.changeState(HubState.CONNECTING);
                this.setError(null);
            }))
            .reconnecting(() => runInAction(() => {
                this.changeState(HubState.RECONNECTING);
                this.setError(null);
            }))
            .reconnected(() => runInAction(() => {
                this.changeState(HubState.CONNECTED);
                this.setError(null);
            }))
            .connectionSlow(() => {
                this.handleSlowConnection();
                this.setError(null);
            })
            .disconnected(() => this.handleDisconnect())
            .error(this.handleError);
    }

    /**
     * Note: Use this over original SignalR start() method.
     * This method ensures valid async handling
     */
    connect(): Promise<void> {
        this.changeState(HubState.CONNECTING);
        this.setError(null);

        return new Promise<void>((resolve, reject) => {
            this.hubConnection.start({ transport: 'webSockets' })
                .done(() => {
                    runInAction(() => {
                        this.changeState(HubState.CONNECTED);
                        this.setError(null);
                    });

                    resolve();
                })
                .fail((err) => {
                    runInAction(() => {
                        this.changeState(HubState.ERROR);
                        this.setError(err);
                    })
                    reject(err);
                })
        })
    }

    /**
     * Disconnects from the hub
     */
    disconnect(): void {
        this.isManualDisconnect = true;
        this.hubConnection.stop(false, true);
        this.changeState(HubState.DISCONNECTED);
        this.setError(null);
    }

    /**
     * Creates hub proxy instance
     * @param hubName hub name
     * @returns instance of hub proxy object
     */
    createHubProxy(hubName: string): SignalR.Hub.Proxy {
        if (isStringNullOrEmpty(hubName)) {
            throw new ArgumentNullError('hubName');
        }

        return this.hubConnection.createHubProxy(hubName);
    }

    /**
     * Changes hub state
     * @param newState new hub state value
     */
    changeState(newState: HubState): void {
        if (this.state !== newState) {
            this.state = newState;
        }
    }

    /**
     * Handles slow connection
     */
    handleSlowConnection() {
        console.log("Slow connection detected.");
    }

    /**
     * Handles connections disconnect
     * Will attempt to reconnect if connection was not dropped by user
     */
    handleDisconnect() {
        if (this.isManualDisconnect) {
            this.changeState(HubState.MANUAL_DISCONNECT);
            this.setError(null);

            clearTimeout(this.reconnectTimeoutId);
        }
        else if (this.options.autoReconnect) {
            this.changeState(HubState.AUTO_RECONNECT);
            this.reconnectTimeoutId = window.setTimeout(() => {
                this.connect();
            }, this.options.reconnectInterval);
        }
        else {
            this.changeState(HubState.DISCONNECTED);
            this.setError(null);
            clearTimeout(this.reconnectTimeoutId);
        }
    }

    /**
     * Handles connection error
     * @param error connection error
     */
    handleError(error: SignalR.ConnectionError) {
        /*
            Note: this is removed because when ping request fails it invokes this method,
            then sets state to error and turns isStarted to false which in turn shuts down
            all application subscriptions
        */
        // this.changeState(HubState.ERROR);
        this.setError(error);
    }

    /**
     * Sets error value
     * @param error connection error
     */
    setError(error: SignalR.ConnectionError) {
        this.error = error;
    }
}

decorate(HubConnection, {
    state: observable,
    error: observable,
    changeState: action.bound,
    disconnect: action.bound,
    handleDisconnect: action.bound,
    handleError: action.bound,
    setError: action.bound
});

export {
    HubConnection
}