import { ArgumentNullError, isStringNullOrEmpty, toQueryParamString } from '@gp/utility';

import { IConnectionParametersFactory, IHubOptions } from "./common";

export interface IHubConnectionParameters {
    shopId: string;
    agency: string;
    locale: string;
    version?: number;
}

/**
 * Example hub connection parameters factory
 */
export class HubConnectionParametersFactory implements IConnectionParametersFactory {
    private locale: string;
    private shopId: string;
    private agency: string;

    /**
     * Hub version
     * 
     * Default version is 1.
     */
    private version: number;

    constructor(params: IHubConnectionParameters) {
        if (params == null) {
            throw new ArgumentNullError('options');
        }

        if (isStringNullOrEmpty(params.shopId)) {
            throw new ArgumentNullError('shopId');
        }
        if (isStringNullOrEmpty(params.agency)) {
            throw new ArgumentNullError('agency');
        }

        this.locale = params.locale || 'en';
        this.shopId = params.shopId;
        this.agency = params.agency;

        if (params.version != null && params.version > 0) {
            this.version = params.version;
        }
        else {
            this.version = 1;
        }

    }

    get(): string {
        return toQueryParamString({
            language: this.locale,
            shopId: this.shopId,
            agency: this.agency,
            version: this.version
        });
    }
}