import { PropertyParam } from "./PropertyParam";

export class OfferFilterParams {
	bettingType?: {
		abrv?: string | PropertyParam<string>
	};
	id?: string | PropertyParam<string>



	isFavorite?: boolean;
}