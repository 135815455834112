export enum HubState {
    NOT_INITIALIZED,
    DISCONNECTED,
    MANUAL_DISCONNECT,
    CONNECTING,
    CONNECTED,
    AUTO_RECONNECT,
    /**
     * Connection dropped, trying to reconnect
     */
    RECONNECTING,
    /**
     * Connection error
     */
    ERROR,
    /**
     * Reconnect after error
     */
    RECONNECTING_AFTER_ERROR
}