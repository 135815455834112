import { inflate } from 'pako';
import { fromEvent, interval, Observable, Subscription as RxjsSubscription } from 'rxjs';

import { OfferSubscriptionResponse } from '@gp/models';

import { BaseHubOptions, Hub, ISubscriptionRequest } from '../..';
import { HubState, IConnection } from '../../common';

class HarHubOptions extends BaseHubOptions {
	name: string = 'HarHub';
	autoRunInterval: number;

	/**
	 * @param autoRunInterval interval between two messages (in ms). Default 100ms
	 */
	constructor(autoRunInterval: number = 100) {
		super();

		this.autoRunInterval = autoRunInterval;
	}
}

const DEFAULT_OPTIONS = new HarHubOptions();

const DUMMY_CONNECTION: IConnection = {
	state: HubState.CONNECTED,
	connect: () => Promise.resolve(),
	disconnect: () => Promise.resolve(),
	createHubProxy: () => null,
	hubConnection: null
};

export class HarHub extends Hub {
	index: number = 0;
	messages: any[];

	/**
	 * 
	 * @param subscriptionId subscription id (e.g. "live"). This is used to filter messages by subscriptionId
	 * @param messages all .HAR file entries
	 */
	constructor(subscriptionId: string, messages: any[], options: HarHubOptions = DEFAULT_OPTIONS) {
		super(options, DUMMY_CONNECTION);

		this.messages = messages
			.filter(e => e._webSocketMessages !== undefined)
			.map(e => e._webSocketMessages)
			.flat()
			.filter(m => m.type == 'receive')
			.map(m => {
				try {
					return JSON.parse(m.data);
				}
				catch (err) {
					return {
						M: []
					}
				}
			})
			.filter(m => m.M != undefined && m.M.length > 0)
			.map(m => m.M)
			.flat()
			.filter(m => m.A != undefined && m.A.length > 0)
			.map(m => m.A)
			.flat()
			.map(m => this._parseMessage(m))
			.filter(syncMsg => syncMsg.subscriptionId == subscriptionId);
	}

	_parseMessage = (rawMessage) => {
		if (typeof rawMessage === 'string') {
			const decoded = atob(rawMessage);
			const charData = decoded.split('').map(i => i.charCodeAt(0));
			const data = inflate(charData);

			return JSON.parse(new TextDecoder('utf-8').decode(data));
		}

		return rawMessage;
	}

	initialize() {
		this.index = 0;
		console.warn('Not implemented for HarHub');
	}

	connect() {
		return Promise.resolve();
	}

	disconnect() {
		this.index = 0;

		return Promise.resolve();
	}

	/**
	 * Gets next message from .HAR file
	 * @returns Next message from HAR
	 */
	getOfferSubscription(request: ISubscriptionRequest): Observable<OfferSubscriptionResponse> {
		console.warn("For debug only! Subscription request is ignored.", request);

		return new Observable<OfferSubscriptionResponse>(subscriber => {
			const keyPress = fromEvent(document, 'keydown');

			const nextMessage = () => {
				if (this.messages.length > this.index) {
					const nextMessage = this.messages[this.index];

					this.index += 1;

					// return nextMessage;
					subscriber.next(nextMessage);
				}
				else {
					subscriber.error('DONE');
				}
			}

			let sub: RxjsSubscription = null;

			keyPress.subscribe((kpe: any) => {
				switch (kpe.keyCode) {
					// SPACE
					case 32:
						nextMessage();
						break;
					// G
					case 71:
						if (sub == null) {
							sub = interval((<HarHubOptions>this.options).autoRunInterval).subscribe(nextMessage);
						}
						else {
							sub.unsubscribe();
							sub = null;
						}
				}
			})

			return () => {
				this.index = 0;
			}
		});

	}
}