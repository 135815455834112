import { PropertyParam } from "./PropertyParam";
import { LiveStreamStatus } from '@gp/models'

export class EventFilterParams {
    id?: string | PropertyParam<string>;
    liveStatus?: number | PropertyParam<number>;
    startTime?: Date | PropertyParam<Date>;
    sportId?: string | PropertyParam<string>;
    sportCategoryId?: string | PropertyParam<string>;
    tournamentId?: string | PropertyParam<string>;
    isUpcoming?: boolean | PropertyParam<boolean>;
    isOutright?: boolean | PropertyParam<boolean>;
    eventType?: number | PropertyParam<number>;
    isTopEvent?: boolean | PropertyParam<boolean>;
    liveStreamStatus?: LiveStreamStatus | PropertyParam<LiveStreamStatus>
    teamOne?: {
        name?: string | PropertyParam<string>
    }
    teamTwo?: {
        name?: string | PropertyParam<string>
    }
    name?: { ilike?: string }
}