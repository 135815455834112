import { Observable } from "rxjs";

import { ArgumentNullError } from "@gp/utility";
import { CountSubscriptionResponse, MenuSubscriptionResponse, OfferSubscriptionResponse, PageableOfferResponse, MostPlayedBySport } from "@gp/models";

import { IConnection, EventPagingFilterParams } from "../../common";
import { IMenuSubscriptionRequest, ICountSubscription, TOfferRequest, ISubscriptionRequest } from "../../common/filter";
import { Hub } from "../../Hub";
import { BettingOfferManagementHubOptions } from "./BettingOfferManagementHubOptions";

export class BettingOfferManagementHub extends Hub {
    /**
     * Creates an instance of Betting offer management hub
     * @param options see BettingOfferManagementHubOptions
     * @param connection
     */
    constructor(options: BettingOfferManagementHubOptions, connection: IConnection) {
        super(options, connection);
    }

    /**
     * Get offer changes subscription for provided request
     * @param request subscription request filter
     * @returns observable that is updated every time new message arrives on the hub
     */
    getOfferSubscription(request: ISubscriptionRequest): Observable<OfferSubscriptionResponse> {
        if (request == null) {
            throw new ArgumentNullError('request');
        }

        return this.createSubscription<OfferSubscriptionResponse>(request.subscriptionId, {
            subscribe: () => this.invoke('Subscribe', request),
            unsubscribe: () => this.invoke('Unsubscribe', request.subscriptionId),
            autoResubscribe: true,
            tryToResubscribeOnError: true
        });
    }

    /**
     * Get menu subscription for provided request
     * @param request subscription request filter
     * @returns observable that is updated every time new message (menu update) arrives on the hub
     */
    getMenuSubscription(request: IMenuSubscriptionRequest): Observable<MenuSubscriptionResponse> {
        if (request == null) {
            throw new ArgumentNullError('request');
        }

        return this.createSubscription<MenuSubscriptionResponse>(request.subscriptionId, {
            subscribe: () => this.invoke('SubscribeToSportMenu', request),
            unsubscribe: () => this.invoke('Unsubscribe', request.subscriptionId),
            autoResubscribe: true,
            tryToResubscribeOnError: true
        });
    }

    /**
     * Get counts based on filter
     * @param request subscription request
     * @returns observable that is updated every time new message (count update) arrives on the hub
     * 
     * @description response object will contain counts with the name of the properties from the request object. First response from the hub will contain all properties, all subsequent responses will return only changed values!
     * 
     * @example
     * getCounts({
     *  subscriptionId: 'test',
     *  countA: {...}, // filter for countA
     *  countB: {...} // filter for countB
     * })
     * 
     * // returns
     * {
     *  subscriptionId: 'test',
     *  countA: 0, // or number of events for the countA filter
     *  countB: 0, // or number of events for the countB filter
     * }
     */
    getCounts(request: ICountSubscription): Observable<CountSubscriptionResponse> {
        if (request == null) {
            throw new ArgumentNullError('request');
        }

        return this.createSubscription<CountSubscriptionResponse>(request.subscriptionId, () => {
            return this.invoke('Count', request);
        });
    }

    /**
     * Gets offer
     * @description Does not update in real-time! This is fire-and-forget method meaning it will return response only when called.
     * @param request offer filter
     * @returns A promise once resolved returns pageable offer response
     */
    getOffer(request: TOfferRequest): Promise<PageableOfferResponse> {
        if (request == null) {
            throw new ArgumentNullError('request');
        }

        return this.invoke<PageableOfferResponse>('GetOffer', request);
    }

    /**
     * Gets most played events
     * @description Does not update in real-time! This is fire-and-forget method meaning it will return response only when called.
     * @param request offer filter
     */
    getMostPlayed(request: TOfferRequest): Promise<PageableOfferResponse> {
        if (request == null) {
            throw new ArgumentNullError('request');
        }

        return this.invoke<PageableOfferResponse>('GetMostPlayed', request);
    }

    getMostPlayedBySport(request?: {
        /** base62 guid */
        sportId?: string;
        language?: string;
        /** default 15 */
        numberOfEvents?: number;
        filter?: EventPagingFilterParams | EventPagingFilterParams[] | null;
    }) {
        return this.invoke<MostPlayedBySport>('GetMostPlayedBySport', request);
    }
}