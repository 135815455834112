import { decorate, when, action, computed, observable } from 'mobx';
import { Observable as RxObservable, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { inflate } from 'pako';

import type { Subscriber } from 'rxjs';

import { ArgumentNullError, isStringNullOrEmpty } from '@gp/utility';

import { IHubOptions, IConnection, HubState, IBaseMessage } from "./common";
import { map, retryWhen } from 'rxjs/operators';

type EventHandler = (...message: any[]) => void;

type Receiver = { eventName: string, handler: EventHandler };
type ReceiversContainer = { eventName: string, handlers: EventHandler[] };

type ReceiverDisposer = () => void;

type DispatcherDescriptor = {
    subscribe: () => Promise<any>,
    unsubscribe: () => Promise<any>,
    subscriber: Subscriber<any>,
    autoResubscribe: boolean,
    started: boolean
}

/**
 * If connection has changed ( changed connection parameters, etc.) use @method initialize
 * This will replace current connection with new one.
 */
class Hub {
    options: IHubOptions;
    connection: IConnection;
    hubInstance: SignalR.Hub.Proxy = null;

    /**
     * Dictionary containing
     */
    dispatchers: Map<string, DispatcherDescriptor> = observable.map(null, {deep: true});

    /**
     * Collection of all hub event receivers
     */
    receivers: ReceiversContainer[] = [];

    /**
     * Indicates state of the hub connections.
     * If true, hub is connected. False otherwise
     */
    get isStarted(): boolean {
        return this.connection && this.connection.state === HubState.CONNECTED;
    }

    /**
     * Creates an instance of Hub
     * @param options hub options
     * @param connection
     */
    constructor(options: IHubOptions, connection: IConnection) {
        this.ensureValidOptions(options);
        this.options = options;

        this.connection = connection;

    }

    /**
     * Initializes hub and registers common events
     * @param connection, if not provided old connection will be used.
     */
    @action
    initialize(connection?: IConnection): void {
        let createHub = false;
        if (connection) {
            this.connection = connection;
            createHub = true;
        } else if (this.hubInstance == null) {
            createHub = true;
        }

        if (createHub) {
            this.hubInstance = this.connection.createHubProxy(this.options.name);

            this.connection.hubConnection.stateChanged(stateChange => {
                if (stateChange.newState == $.signalR.connectionState.connected) {
                    if (stateChange.oldState == $.signalR.connectionState.reconnecting) {
                        this.dispatchers.forEach(dispatcher => {
                            if (dispatcher.unsubscribe && dispatcher.started) {
                                dispatcher.unsubscribe()
                                    .catch(err => this.options.logger.logWarn('Unsubscribe failed', err))
                                    .finally(() => dispatcher.subscribe());
                            } else {
                                dispatcher.subscribe();
                            }
                        });
                    } else {
                        this.dispatchers.forEach(dispatcher => {
                            if (dispatcher.autoResubscribe) {
                                dispatcher.subscribe();
                            }
                        });
                    }
                }
            });

            this.registerCommonEvents();

            if (this.connection.state == HubState.CONNECTED) {
                this.dispatchers.forEach(dispatcher => dispatcher.subscribe());
            }
        }
    }

    /**
     * Unregisters hub receiver events
     * 
     * @description after you call unregister, you won't be subscribed to messages from the server. If you want to subscribe to the messages again, call RegisterCommonEvents()
     */
    terminate() {
        if (this.hubInstance != null) {
            // unbind all event handlers on the hub
            this.receivers.map(r => r.eventName).forEach(eventName => this.hubInstance.off(eventName));
            this.receivers = [];
        }
    }

    /**
     * Invokes a method on hub.
     * WARNING: do not use SignalR hub invoke method!
     * @param methodName method to invoke
     * @param params optional parameters
     * @returns returns promise that resolves once method has been invoked, or rejects if an error occurred
     */
    async invoke<T>(methodName: string, ...params: any[]): Promise<T> {
        if (this.hubInstance == null) {
            throw new Error('Hub method invocation is not available. Hub needs to be initialized!');
        }

        if (this.connection.state === HubState.NOT_INITIALIZED) {
            throw new Error('Hub needs to be started first! Call .connect() prior to invoke.');
        }

        if (this.connection.state === HubState.DISCONNECTED) {
            throw new Error('Hub is disconnected.');
        }

        if (this.connection.state === HubState.MANUAL_DISCONNECT) {
            throw new Error('Hub was manually disconnected.');
        }

        if (!this.isStarted && (
            this.connection.state === HubState.CONNECTING ||
            this.connection.state === HubState.RECONNECTING ||
            this.connection.state === HubState.AUTO_RECONNECT ||
            this.connection.state === HubState.RECONNECTING_AFTER_ERROR
        )) {
            this.options.logger.logWarn('Connection is not yet started. Waiting...');
            await when(() => this.isStarted);
        }

        return await this.hubInstance.invoke(methodName, ...params);
    }

    /**
     * Registers receiver callback method for the given event and binds it to the hub
     * @param receiver receiver object. All properties are mandatory
     * @returns disposer function, once called, clears registered receiver and unbinds it from the hub
     */
    registerReceiver(receiver: Receiver): ReceiverDisposer {
        if (receiver == null) {
            throw new ArgumentNullError('receiver');
        }
        if (isStringNullOrEmpty(receiver.eventName)) {
            throw new ArgumentNullError('receiver.eventName');
        }
        if (receiver.handler == null) {
            throw new ArgumentNullError('receiver.handler');
        }

        const existingReceiver = this.receivers.find(r => r.eventName === receiver.eventName);

        if (existingReceiver == null) {
            // add handler to an existing event
            existingReceiver.handlers.push(receiver.handler);
        }
        else {
            // add new event with handler
            this.receivers.push({
                eventName: receiver.eventName,
                handlers: [receiver.handler]
            });
        }

        // register new handler on the hub if hub was initialized
        if (this.hubInstance != null) {
            this.hubInstance.on(receiver.eventName, receiver.handler);
        }

        return () => {
            // destroy receiver
            const currHandlerReceiverIdx = this.receivers.findIndex(r => r.eventName === receiver.eventName);
            if (currHandlerReceiverIdx !== -1) {
                const currReceiver = this.receivers[currHandlerReceiverIdx];
                const handlerIdx = currReceiver.handlers.indexOf(receiver.handler);
                if (handlerIdx !== -1) {
                    this.receivers[currHandlerReceiverIdx].handlers.splice(handlerIdx, 1);
                }
            }

            // kill handler on the hub if hub was initialized
            if (this.hubInstance) {
                this.hubInstance.off(receiver.eventName, receiver.handler);
            }
        };
    }

    /**
     * Creates offer subscription
     * @param subscriptionId subscription id
     * @param invocationFn invoke function call
     * @param unsubscribe invoke clear state
     * @returns 
     */
    protected createSubscription<T>(subscriptionId: string, options: (any | (() => Promise<any>)), unsubscribe: () => Promise<any> = undefined): RxObservable<T> {
        let subscribe;
        let autoResubscribe = this.options.autoResubscribe === true ? true : false;
        let tryToResubscribeOnError;
        if (typeof options === 'function') {
            subscribe = options;
            tryToResubscribeOnError = false;
        } else {
            if (options == undefined || options == null || options.subscribe == undefined || options.subscribe == null) {
                throw new Error("createSubscription requires subscribe function");
            }
            subscribe = options.subscribe;
            unsubscribe = options.unsubscribe;
            if (options.autoResubscribe != undefined || options.autoResubscribe != null) {
                autoResubscribe = options.autoResubscribe === true ? true : false;
            }
            tryToResubscribeOnError = options.tryToResubscribeOnError === true ? true : false;
        }

        if (unsubscribe == undefined) {
            unsubscribe = () => this.invoke('Unsubscribe', subscriptionId);
        }

        let subscriptionObservable = new RxObservable<T>(subsriber => {
            const dispatcherDescriptor = {
                started: false,
                autoResubscribe: autoResubscribe,
                subscriber: subsriber,
                subscribe: null,
                unsubscribe: unsubscribe
            };

            dispatcherDescriptor.subscribe = () => {
                dispatcherDescriptor.started = true;
                return subscribe()
                    .catch(err => {
                        this.dispatchers.delete(subscriptionId);
                        subsriber.error(err);
                    });
            };

            this.dispatchers.set(subscriptionId, dispatcherDescriptor);
            // if connection is not ready, recovery will take care of calling it when ready
            if (this.isStarted) {
                dispatcherDescriptor.subscribe();
            }

            return async () => {
                this.dispatchers.delete(subscriptionId);

                if (this.isStarted && unsubscribe) {
                    try {
                        await unsubscribe();
                    }
                    catch (err) {
                        this.options.logger.logWarn('Unsubscribe failed', err);
                    }
                }
            }
        });

        if (tryToResubscribeOnError) {
            const retryConfig = {
                count: 3,
                delay: 3000,
                resetOnSuccess: true,
            };

            subscriptionObservable = subscriptionObservable
                .pipe(retry(retryConfig));
        }

        return subscriptionObservable;
    }

    /**
     * Invokes underlying subscribers to pass new messages or errors
     * @param message incoming message
     */
    protected handleEventMessage = (message: string | IBaseMessage) => {
        // if message is string, it means it is encoded and zipped
        if (typeof message === 'string') {
            message = this.decodeMessage(message);
        }
        // message contains only compressed data
        else if (message.compressedMessage != null) {
            message = {
                subscriptionId: message.subscriptionId,
                error: message.error,
                ...this.decodeMessage(message.compressedMessage)
            }
        }

        let dispatcher: DispatcherDescriptor;
        
        if(Array.isArray(message)){
            if (message.length > 0) {
                dispatcher = this.dispatchers.get(message[0].subscriptionId);
            } else {
                //no need to go further, this is empty message
                return;
            }
        }
        else {
            dispatcher = this.dispatchers.get(message.subscriptionId);
        }

        if (dispatcher != null) {
            if (message.error != null) {
                dispatcher.subscriber.error(message.error);
                // unsubscribe after error
                dispatcher.subscriber.unsubscribe();

                this.dispatchers.delete(message.subscriptionId);
            }
            else {
                dispatcher.subscriber.next(message);
            }
        }
        else {
            this.options.logger.logError(
                `Dispatcher was not found. SubscriptionId: ${message?.subscriptionId || message?.[0]?.subscriptionId} Dispatchers: ${Array.from(this.dispatchers.keys())}`
            );
        }
    }

    /**
     * Decodes compressed message from the hub
     * @param message message to decode
     * @returns decoded message, in case input is null or empty returns undefined
     */
    protected decodeMessage(message: string): IBaseMessage | undefined {
        if (message == null || message.trim() === '') {
            return undefined;
        }

        const decoded = atob(message);
        const charData = decoded.split('').map(i => i.charCodeAt(0));
        const data = inflate(charData);

        const parsedMessage = JSON.parse(new TextDecoder("utf-8").decode(data)) as IBaseMessage;

        if (this.options.enableCompressedMessageLogging) {
            this.options.logger.logInfo('Unzipped/compressed message', parsedMessage);
        }

        return parsedMessage;
    }

    /**
     * Registers common event handlers
     */
    protected registerCommonEvents(): void {
        if (this.hubInstance == null) {
            throw new Error("Can not register common receivers because hub is not initialized.");
        }

        const mapDefaultReceivers: (eventName: string) => ReceiversContainer = (eventName) => {
            return {
                eventName: eventName,
                handlers: [this.handleEventMessage]
            }
        }

        this.options.commonEventNames
            .map(eventName => mapDefaultReceivers(eventName))
            .concat(this.receivers)
            .forEach(receiver => {
                receiver.handlers.forEach(handler => this.hubInstance.on(receiver.eventName, handler));
            });
    }

    protected ensureValidOptions(options: IHubOptions): void {
        if (isStringNullOrEmpty(options.name)) {
            throw new ArgumentNullError('options.name');
        }
    }
}

decorate(Hub, {
    dispatchers: observable,
    isStarted: computed,
    connection: observable,
    initialize: action,
    terminate: action,
    registerReceiver: action,
});

export {
    Hub
}