import { Observable } from "rxjs";

import { ArgumentNullError } from "@gp/utility";

import { ICashoutSubscriptionRequest, ICashoutSubscriptionResponse, IConnection, IConnectionFactory } from "../../common";
import { Hub } from "../../Hub";
import { CashoutHubOptions } from "./CashoutHubOptions";

/**
 * CashoutHub is used for real-time cashout value updates
 */
export class CashoutHub extends Hub {
	/**
	 * Creates an instance of Cashout hub
	 * @param options
	 * @param connection 
	 */
	constructor(options: CashoutHubOptions, connection: IConnection) {
		super(options, connection);
	}

	/**
	 * Get cashout changes for slips sent in the request filter
	 * @param request subscription request filter
	 * @returns observable that is updated every time new message arrives on the hub
	 */
	getSubscription(request: ICashoutSubscriptionRequest): Observable<ICashoutSubscriptionResponse> {
		if (request == null) {
			throw new ArgumentNullError('request');
		}

		return this.createSubscription<ICashoutSubscriptionResponse>(request.subscriptionId, () => {
			return this.invoke('Subscribe', request);
		}, () => this.invoke('Unsubscribe', { subscriptionId: request.subscriptionId }));
	}
}