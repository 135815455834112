export enum CashoutType {
	NO_CACHE_OUT = 0,
	JACKTIME = 1,
	NORMAL = 2,
}

export interface IBetSlipContainer {
	betSlipNumericalId: string;
	cType: CashoutType;
	dateCreated: string;
	id: string;
	isPaidOut: boolean;
	maxGain?: number;
	timeStamp: string;
	value?: number;
}
